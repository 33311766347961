<template>
  <el-dialog
    v-if="absentUntil"
    :title="$t('messages.counselor.absence.title')"
    :visible.sync="open"
    :modal-append-to-body="false"
    custom-class="el-dialog--small"
    @before-close="$emit('closeDialog')"
  >
    <div v-html="content" />

    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        :loading="waitLoading"
        type="primary"
        @click="closeDialog(false)"
      >
        {{ $t('system.yes') }}
      </el-button>

      <el-button
        :loading="forwardLoading"
        type="primary"
        @click="closeDialog(true)"
      >
        {{ $t('system.no') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    confirmIsOpen: {
      type: Boolean,
      default: false
    },
    waitLoading: {
      type: Boolean,
      default: false
    },
    forwardLoading: {
      type: Boolean,
      default: false
    },
    absentUntil: {
      type: [Date, Boolean, String],
      default: false
    }
  },

  data: () => ({
    open: false
  }),

  computed: {
    content() {
      const date = format(this.absentUntil, 'DD.MM.yyyy')
      return this.$t('messages.counselor.absence.content').replace('%s', date)
    }
  },

  watch: {
    confirmIsOpen: function(newVal) {
      this.open = newVal
    },

    open: function(newVal) {
      if (newVal === false) {
        this.$emit('close-dialog')
      }
    }
  },

  methods: {
    closeDialog(forward) {
      this.$emit('close-absent-dialog', forward)
    }
  }
}
</script>
